import React from 'react';
import { smoothScrollToSection } from '../smoothScroll';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about-image"></div>
      <div className="about-text-wrapper">
        <header className="about-header poiret-one-bold">
          About me
        </header>
        <article className="about-text poiret-one-regular">
          <p>Hi, I'm Michele! With over 7 years of teaching experience in Sydney and the picturesque Sutherland Shire, I create a nurturing environment where you can reconnect with yourself. My classes focus on slowing down, calming the mind, and discovering freedom within your body. The teaching style is slow yet strong, suitable for all levels, with modifications offered as needed and an emphasis on being guided by your own intuition. You'll leave each class feeling grounded, balanced, and with a sense of coming home to your true self.</p>
        </article>
        <button className="get-in-touch-button poiret-one-bold" onClick={() => smoothScrollToSection('footer')}>
          Get in touch
        </button>
      </div>
    </section>
  );
};

export default About;
