import React from 'react';
import './App.css';
import HamburgerMenu from './components/HamburgerMenu';
import Hero from './components/Hero';
import Quote from './components/Quote';
import About from './components/About';
import Corporate from './components/Corporate';
import Private from './components/Private';
import Health from './components/Health';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <HamburgerMenu />
      <Hero />
      <Quote />
      <About />
      <Private />
      <Corporate />
      <Health /> 
      <Footer />
    </div>
  );
}

export default App;
