import React from 'react';
import './Corporate.css';
import { smoothScrollToSection } from '../smoothScroll';

const Corporate = () => {
  return (
    <section id="corporate-wellness" className="corporate">
      <div className="corporate-image"></div>
      <div className="corporate-text-wrapper">
        <div className="corporate-header poiret-one-bold">
          Corporate Wellness
        </div>
        <article className="corporate-text poiret-one-regular">
          <p>These corporate yoga classes are designed to enhance employee well-being, reduce stress, and boost productivity. Tailored to suit all levels, these sessions focus on relaxation, mindfulness, and physical health. Whether in-office, at a nearby studio, or outdoors, these classes bring a refreshing break to the workday, promoting a healthier and more focused workplace.<br /><br />Areas include the Sutherland Shire and Sydney CBD, as well as the surrounding
              areas.<br/><br/>Prices start from $180</p>
        </article>
        <button className="get-in-touch-button poiret-one-bold" onClick={() => smoothScrollToSection('footer')}>
          Get in touch
        </button>
      </div>
    </section>
  );
};

export default Corporate;
