import React from 'react';
import './Footer.css';
import { FaInstagram, FaFacebook } from 'react-icons/fa'; // Using react-icons for social icons
import logo from '../images/logo1-large.jpg'; // Adjust the path to your logo image
import ContactForm from './ContactForm';

const Footer = () => {
    return (
        <footer id="footer" className="footer-section poiret-one-regular">
            <img src={logo} alt="Logo" className="footer-logo" />
            <div className="footer-content">
                <ContactForm />
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13221.747928873172!2d151.1438996332564!3d-34.05831074341889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12c81235d88f33%3A0x5017d681632b360!2sCronulla%20NSW%202230!5e0!3m2!1sen!2sau!4v1722502167269!5m2!1sen!2sau"
                    width="600"
                    height="450"
                    style={{ border: 1 }}
                    allowFullScreen=""
                    loading="lazy"
                    className="footer-map"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <div className="footer-contact">
                <p>e: michmarquet@gmail.com
                    <br />p: +61 416 948 003</p>
            </div>
            <div className="footer-social">
                <a href="https://www.instagram.com/michmarquet/?hl=en" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                </a>
                <a href="https://www.facebook.com/michele.marquet.3" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
