import React from 'react';
import './Quote.css';
import logo from '../images/logo1-large.jpg'; // Adjust the path to your logo image

const Quote = () => {
  return (
    <div className="quote-section">
      <img src={logo} alt="Logo" className="quote-logo" />
      <p className="quote-text">Come to the mat, expect nothing, notice everything</p>
    </div>
  );
};

export default Quote;
