import React from 'react';
import { smoothScrollToSection } from '../smoothScroll';
import './Health.css';

const Health = () => {
  return (
    <section id="health-coaching" className="health">
      <div className="health-text-wrapper">
        <div className="health-header poiret-one-bold">
          Health Coaching
        </div>
        <article className="health-text poiret-one-regular">
          <p>When you prioritise good nutrition and healthy habits, results naturally follow. Michele, a qualified health coach, is dedicated to assisting others in fueling their bodies with quality nutrition. Her straightforward, sustainable system aids in weight loss (and maintenance!), gut health, sustained energy, building lean muscle mass, reducing stress, improving sleep, and much more. Whatever your health objectives may be, there's an option to support you in achieving them.</p>
        </article>
        <button className="get-in-touch-button poiret-one-bold" onClick={() => smoothScrollToSection('footer')}>
          Get in touch
        </button>
      </div>
      <div className="health-image"></div>
    </section>
  );
};

export default Health;
