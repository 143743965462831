import React from 'react';
import './Hero.css';
import { menuItems } from './menuItems';
import { smoothScrollToSection } from '../smoothScroll';

const Hero = () => {
  

  return (
    <div className="hero">
      <div className="hero-menu">
        {menuItems.map((item, index) => (
          <button 
            className='julius-sans-one-regular'
            key={index} 
            onClick={() => smoothScrollToSection(item.link.substring(1))}
            style={{ color: item.color }}
          >
            {item.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Hero;
