import React from 'react';
import { smoothScrollToSection } from '../smoothScroll';
import './Private.css';

const Private = () => {
  return (
    <section id="private-yoga" className="private">
      <div className="private-text-wrapper">
        <div className="private-header poiret-one-bold">
          Private Yoga
        </div>
        <article className="private-text poiret-one-regular">
          <p>Private yoga sessions offer personalised instruction tailored to your individual needs and goals. Whether you're a beginner or an experienced practitioner, Michele will provide a class suited to you, to help you deepen your practice, improve technique, and achieve your wellness objectives. Enjoy the flexibility of scheduling and the comfort of practicing in your own space, or nearby studio with sessions designed just for you.<br /><br />Areas include the Sutherland Shire and Sydney CBD, as well as the surrounding
              areas.<br/><br/>Prices start from $120</p>
        </article>
        <button className="get-in-touch-button poiret-one-bold" onClick={() => smoothScrollToSection('footer')}>
          Get in touch
        </button>
      </div>
      <div className="private-image"></div>
    </section>
  );
};

export default Private;
