import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    enquiry: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedbackMessage('');

    emailjs.send('service_44xfn4u', 'template_u5pi60d', formData, 'ylVXf_M4Q2dbIJfQc')
      .then((result) => {
          console.log('Email successfully sent!', result.text);
          setFeedbackMessage('Thanks for your enquiry!');
          setIsSubmitting(false);
      }, (error) => {
          console.log('Failed to send email.', error.text);
          setFeedbackMessage('Failed to send email. Please try again.');
          setIsSubmitting(false);
      });

    setFormData({
      name: '',
      phone: '',
      email: '',
      enquiry: ''
    });
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Phone</label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Enquiry</label>
        <textarea
          name="enquiry"
          value={formData.enquiry}
          onChange={handleChange}
          rows="6"
          required
        />
      </div>
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Sending...' : 'Send'}
      </button>
      {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
    </form>
  );
};

export default ContactForm;
