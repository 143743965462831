import React, { useState } from 'react';
import Sidebar from './Sidebar';
import './HamburgerMenu.css';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={`hamburger-menu ${isOpen ? 'hidden' : ''}`} onClick={toggleSidebar}>
        &#9776;
      </div>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
    </>
  );
};

export default HamburgerMenu;
