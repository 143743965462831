import React from 'react';
import './Sidebar.css';
import { menuItems } from './menuItems';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const smoothScrollToSection = (targetId) => {
    const targetSection = document.getElementById(targetId);

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth'
      });
      toggleSidebar(); // Close the sidebar after scrolling
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="close-btn" onClick={toggleSidebar}>&times;</div>
      <ul>
        {menuItems.map((item, index) => (
          <li 
            className='julius-sans-one-regular' 
            key={index} 
            onClick={() => smoothScrollToSection(item.link.substring(1))} // Remove '#' from link
            style={{ color: item.color }}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
